/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AttachDialogMode } from '@app/cluster-management/dialog-attach-cluster/types';
import {
  AttachClusterButtonComponent,
  AttachClusterButtonTemplateDirective,
} from '@app/common/components/attach-cluster-button/attach-cluster-button.component';
import { CreateNebulaClusterButtonModule } from '@app/common/components/create-nebula-cluster-button/create-nebula-cluster-button.module';

import { ShowForTargetDirective } from '@app/common/modules/ggcc-target/show-for-target.directive';
import { Angulartics2OnModule } from 'angulartics2';

@Component({
  selector: 'button-add-cluster',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './button-add-cluster.component.html',
  styleUrls: ['./button-add-cluster.component.scss'],
  imports: [
    RouterModule,
    AsyncPipe,
    Angulartics2OnModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    ShowForTargetDirective,
    AttachClusterButtonComponent,
    AttachClusterButtonTemplateDirective,
    CreateNebulaClusterButtonModule,
  ],
})
export class ButtonAddClusterComponent {
  public readonly AttachDialogMode = AttachDialogMode;
}
