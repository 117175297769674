/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { handleLoading } from '@app/common/rxjs-operators';
import { extractError } from '@app/common/utils';
import { interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { clusterList, currentClusterInfo, isCurrentClusterInitialized } from '@app/core/ngrx/app.selectors';
import { currentCluster3xHasNodesRunning } from '@app/core/ngrx/cluster-3x.selectors';
import { isCluster3xSelector } from '@app/core/ngrx/cluster.selectors';
import { Clusters3xApiService } from '@app/core/services/clusters-3x.service';
import { DistributionZoneApiService } from '@app/core/services/distribution-zone.service';
import { PartitionStateApiService } from '@app/core/services/partition-state.service';
import { TablesApiService } from '@app/core/services/tables.service';
import { AppState } from '@app/core/types';
import {
  MONITORING_DASHBOARD_GROUP_NAME,
  MY_CLUSTER_DASHBOARD_ID,
} from '@app/monitoring-dashboard/constants/dashboard-id';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { isCluster3x } from '@shared/domain/cluster';
import { isEqual } from 'lodash-es';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { login, logoutSuccess } from './actions/auth.actions';
import {
  cluster3xConfiguration,
  cluster3xConfigurationErr,
  cluster3xDistributionZones,
  cluster3xDistributionZonesErr,
  cluster3xLocalPartitionStateErr,
  cluster3xLocalPartitionStates,
  cluster3xTables,
  cluster3xTablesErr,
  cluster3xTopology,
  cluster3xUpdateClusterMonitoringConfiguration,
  cluster3xUpdateClusterMonitoringConfigurationErr,
  cluster3xUpdateClusterMonitoringConfigurationOk,
  cluster3xUpdateConfiguration,
  cluster3xUpdateConfigurationErr,
  cluster3xUpdateConfigurationOk,
  clusterConnectorsAction,
  getCluster3xConfiguration,
  initializeCluster,
  initializeClusterErr,
  initializeClusterOK,
  loadCluster3xDistributionZones,
  loadCluster3xLocalPartitionState,
  loadCluster3xTables,
  loadIgnite3PhysicalTopologyErr,
} from './actions/cluster-3x.actions';

@Injectable()
export class Clusters3xEffects {
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);
  private matDialog = inject(MatDialog);
  private clusters3xApi = inject(Clusters3xApiService);
  private tablesApi = inject(TablesApiService);
  private distributionZoneApi = inject(DistributionZoneApiService);
  private partitionStateApi = inject(PartitionStateApiService);

  loading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        { start: initializeCluster, end: [initializeClusterOK, initializeClusterErr] },
        { start: cluster3xUpdateConfiguration, end: [cluster3xUpdateConfigurationOk, cluster3xUpdateConfigurationErr] },
        {
          start: cluster3xUpdateClusterMonitoringConfiguration,
          end: [cluster3xUpdateClusterMonitoringConfigurationOk, cluster3xUpdateClusterMonitoringConfigurationErr],
        },
      ]),
    ),
  );

  initializeClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeCluster),
      switchMap((action) =>
        this.clusters3xApi.initialize(action.clusterId, action).pipe(
          map(() => initializeClusterOK({ notification: 'Cluster initialized' })),
          catchError((err) =>
            of(initializeClusterErr({ error: extractError(err, 'Failed to initialize the cluster') })),
          ),
        ),
      ),
    ),
  );

  closeInitializeDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeClusterOK),
        tap(() => this.matDialog.closeAll()),
      ),
    { dispatch: false },
  );

  notifyAboutTopologyLoadError$ = createEffect(() =>
    interceptActionTaskErrors(this.clusters3xApi, 'getPhysicalTopology').pipe(
      map((err) =>
        loadIgnite3PhysicalTopologyErr({
          error: extractError(err, 'Failed to load cluster physical topology'),
        }),
      ),
    ),
  );

  clusterTopology$ = createEffect(() =>
    this.store.select(clusterList).pipe(
      // Can't use filter here because switchMap ensures we unsubscribe when there's no cluster ID
      switchMap((clusters) =>
        merge(
          ...clusters
            .filter((cluster) => isCluster3x(cluster))
            .map((cluster) =>
              this.clusters3xApi
                .clusterTopology(cluster.id)
                .pipe(map((data) => cluster3xTopology({ data, clusterId: cluster.id }))),
            ),
        ),
      ),
    ),
  );

  // Connectors

  clusterConnectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(() =>
        this.clusters3xApi.clusterConnectors().pipe(
          map((data) => clusterConnectorsAction({ data })),
          takeUntil(this.actions$.pipe(ofType(logoutSuccess))),
        ),
      ),
    ),
  );

  // Tables and Distribution zones

  loadTablesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xTables({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xTables),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.tablesApi.tables(clusterId).pipe(
              map((data) => cluster3xTables({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xTablesErr({
                    error: extractError(err, `Failed to get tables list`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  loadDistributionZonesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xDistributionZones({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xDistributionZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xDistributionZones),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.distributionZoneApi.distributionZones(clusterId).pipe(
              map((data) => cluster3xDistributionZones({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xDistributionZonesErr({
                    error: extractError(err, `Failed to get distribution zones`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  // Local and Global partitions

  loadLocalPartitionStatesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xLocalPartitionState({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xLocalPartitionState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xLocalPartitionState),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.partitionStateApi.localPartitionStates(clusterId).pipe(
              map((data) => cluster3xLocalPartitionStates({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xLocalPartitionStateErr({
                    error: extractError(err, `Failed to get local partition state`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  // Cluster configuration
  loadCluster3xConfiguration$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map(
          (e: RouterNavigatedAction) =>
            (e.payload.event.url.includes(MONITORING_DASHBOARD_GROUP_NAME) &&
              !e.payload.event.url.includes(MY_CLUSTER_DASHBOARD_ID)) ||
            e.payload.event.url.includes('alerting'),
        ),
      ),
      this.store.select(isCurrentClusterInitialized),
      this.store.select(currentCluster3xHasNodesRunning),
    ]).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      withLatestFrom(this.store.select(currentClusterInfo)),
      switchMap(([[shouldLoad, isCurrentClusterInitialized, hasNodesRunning], cluster]) =>
        shouldLoad && cluster && isCluster3x(cluster) && isCurrentClusterInitialized && hasNodesRunning
          ? [getCluster3xConfiguration({ clusterId: cluster.id })]
          : EMPTY,
      ),
    ),
  );

  refreshClusterConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cluster3xUpdateConfigurationOk),
      switchMap(({ clusterId }) => (clusterId ? of(getCluster3xConfiguration({ clusterId })) : EMPTY)),
    ),
  );

  getCluster3xConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCluster3xConfiguration),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.clusters3xApi.getClusterConfiguration(clusterId).pipe(
              map((configuration) => cluster3xConfiguration({ clusterId, configuration })),
              catchError((err) =>
                of(
                  cluster3xConfigurationErr({
                    error: extractError(err, `Failed to get cluster configuration`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  updateCluster3xConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cluster3xUpdateConfiguration),
      switchMap(({ clusterId, configuration }) =>
        clusterId
          ? this.clusters3xApi.updateClusterConfiguration(clusterId, configuration).pipe(
              map(() => cluster3xUpdateConfigurationOk({ notification: 'Cluster configuration updated', clusterId })),
              catchError((err) =>
                of(
                  cluster3xUpdateConfigurationErr({
                    error: extractError(err, 'Failed to update cluster configuration'),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  updateCluster3xMonitoringConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cluster3xUpdateClusterMonitoringConfiguration),
      withLatestFrom(this.store.select(isCluster3xSelector)),
      switchMap(([{ clusterId, configuration }, is3x]) =>
        clusterId && is3x
          ? this.clusters3xApi.updateClusterMonitoringConfiguration(clusterId, configuration).pipe(
              map(() =>
                cluster3xUpdateClusterMonitoringConfigurationOk({
                  notification: 'Cluster configuration updated',
                  clusterId,
                }),
              ),
              catchError((err) =>
                of(
                  cluster3xUpdateClusterMonitoringConfigurationErr({
                    error: extractError(err, 'Failed to update cluster configuration'),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );
}
