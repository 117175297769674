/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const iconsAppInitializer = provideAppInitializer(() => {
  const iconRegistry = inject(MatIconRegistry);
  const sanitizer = inject(DomSanitizer);
  iconRegistry.addSvgIconResolver((name, namespace: string) => {
    // Namespaces: "ggcc", "material-filled", "material-outlined", "material-symbols-outlined"
    return sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icons/${namespace}/${name}.icon.svg`);
  });
});

@NgModule({ providers: [iconsAppInitializer, provideHttpClient(withInterceptorsFromDi())] })
export class GmcIconsTestingModule {}
