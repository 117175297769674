/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

current-cluster-select {
  max-width: 322px;
  --mat-select-trigger-text-weight: 500;
  --mat-select-disabled-trigger-text-color: var(--button-color);
  --mat-select-enabled-trigger-text-color: var(--button-color);
  --mat-select-enabled-arrow-color: var(--button-color);

  mat-select {
    position: relative;
    top: -2px;
    &:not(.single) {
      .mat-mdc-select-arrow {
        margin-left: 12px;
      }
    }
    &.single {
      .mat-mdc-select-arrow {
        width: 0;
        height: 0;
        border: 0;
        margin: 0 4px;
        svg {
          width: 0;
          height: 0;
        }
      }
    }
  }
  mat-select-trigger {
    cluster-status {
      margin-right: 12px;
    }
  }
}
cluster-status.inline-status {
  display: inline-flex;
}
mat-option cluster-status.inline-status {
  position: relative;
  top: -3px;
}
