<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

@if (notification(); as notification) {
  <div class="notification" (click)="navigate()" gta="notification-container">
    <div class="notification__title" gta="notification-container__title" detectEllipsis matTooltip>
      {{ notification.title }}
    </div>
    <div class="notification__message" gta="notification-container__message" detectEllipsis matTooltip [multiline]="2">
      {{ notification.message }}
    </div>
    <div class="notification__bottom-bar">
      <div class="notification__bottom-bar__info">
        <button
          mat-icon-button
          [ngClass]="{ filled: notification.read }"
          (click)="toggleNotification($event)"
          [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
          gta="notification-container__toggle-trigger"
        >
          <span class="dot"></span>
        </button>
        <span class="material-emphasis-medium creation-time" gta="notification-container__date">
          {{ notification.createdAt | dateAgo: 'minute' : false }}</span
        >
        @if (clusterTag(); as clusterTag) {
          <span>·</span>
          <span detectEllipsis matTooltip class="material-emphasis-medium" gta="notification-container__cluster-tag">
            {{ clusterTag }}</span
          >
        }
      </div>
      @if (showActions()) {
        <div class="notification__bottom-bar__actions">
          <button
            mat-button
            type="button"
            color="primary"
            (click)="dismiss($event)"
            gta="notification-container__dismiss-trigger"
            >Dismiss</button
          >
        </div>
      }
    </div>
  </div>
}
