/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { GA4_TRACKING_ID, GA_TRACKING_ID } from '@app/injection-tokens';
import { EffectsModule } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsGoogle } from './analytics-google';
import { AnalyticsEffects } from './analytics.effects';
import { ANALYTICS_QUERY_PARAMS } from './query-params';

@NgModule({
  providers: [
    { provide: GA_TRACKING_ID, useValue: 'UA-1382082-18' },
    { provide: GA4_TRACKING_ID, useValue: 'G-4YQ8W8TM8L' },
    provideAppInitializer(() => inject(AnalyticsGoogle).init()),
    provideAppInitializer(() => {
      const cookies = inject(CookieService);
      const url = new URL(window.location.href);
      for (const param of ANALYTICS_QUERY_PARAMS) {
        const value = url.searchParams.get(param);
        if (value) cookies.set(param, value, 1, '/');
      }
    }),
  ],
  imports: [EffectsModule.forFeature([AnalyticsEffects])],
})
export class AnalyticsModule {}
