/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { ClusterActionService } from '@app/core/services';
import { simpleHook } from '@common/utils/simple-hook';
import { SECURE_CLUSTER_SESSION_NOT_FOUND } from '@shared/constants/api-error-codes';
import { ClusterActionResponseError } from '@shared/types/cluster-actions';
import { catchError, throwError } from 'rxjs';
import { DialogSecureClusterSignInService } from './common/components/dialog-secure-cluster-sign-in/dialog-secure-cluster-sign-in.service';

export const secureClusterSessionAppInitializer: EnvironmentProviders = provideAppInitializer(() => {
  const actions = inject(ClusterActionService);
  const secureClusterSignInDialog = inject(DialogSecureClusterSignInService);
  simpleHook(actions, 'dispatch', (response, args) =>
    response.pipe(
      catchError((err: ClusterActionResponseError[]) => {
        if (err.some((e) => e.code === SECURE_CLUSTER_SESSION_NOT_FOUND)) {
          return secureClusterSignInDialog.askForCredentials({
            retry: () => actions.dispatch(...args),
            send: (credentials) => actions.dispatch(args[0], args[1], { ...(args[2] || {}), credentials }),
            error: () => throwError(() => err),
            dialogData: { connectionToken: 'foo', showConnectionTokenInput: false },
          });
        } else {
          return throwError(() => err);
        }
      }),
    ),
  );
});
