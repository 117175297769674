/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */
import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { targetSelector } from '@app/core/ngrx';
import { GmcStompConnectionError } from '@app/core/services';
import { DatadogService } from '@app/core/services/datadog.service';
import { ErrorSincService, HandledError } from '@app/core/services/error-sinc.service';
import { IS_PRODUCTION } from '@app/injection-tokens';
import { extractError } from '@common/utils';
import { Store } from '@ngrx/store';
import { isObject } from 'lodash-es';

const getErrorMessage = (error: HandledError) => {
  const baseValue: string = error.error.toString();
  if (!isObject(error.error)) return baseValue;

  if (error.source === 'STOMP') {
    const stompError = error.error as GmcStompConnectionError;
    return stompError.error.message ?? stompError.frame.headers.message ?? 'Unknown';
  }
  if (error.source === 'global') {
    return (error.error as ErrorEvent).message;
  }
  if (error.source === 'effect') {
    return extractError(error.error);
  }

  return baseValue;
};

export const datadogAppInitializer: EnvironmentProviders = provideAppInitializer(async () => {
  const isProduction = inject(IS_PRODUCTION);
  const errorSinc = inject(ErrorSincService);
  const datadog = inject(DatadogService);
  const store = inject(Store);

  const target = store.selectSignal(targetSelector)();
  if (isProduction && target === 'hosted') {
    errorSinc.subscribe().subscribe((error) => {
      const silentPrefix = error.silent ? ' [SILENT]' : '';
      const errorMessage = getErrorMessage(error);
      const message = `${errorMessage}${silentPrefix}`;
      datadog.onError({
        message,
        messageContext: { source: error.source, silent: error.silent },
        error: error.error,
      });
    });
    return await datadog.initialize();
  }
});
