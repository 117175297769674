/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { IconDotDirective } from '@app/common/components/icon-dot/icon-dot.directive';
import { dismissAllNotifications } from '@app/core/notification/ngrx';
import { hasNewNotifications, hasUnreadNotifications } from '@app/core/notification/ngrx/notifications.selectors';
import { ToastService } from '@app/core/notification/services/notification-toast.service';
import { NotificationsState } from '@app/core/notification/types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationOverlayComponent } from '../notification-overlay/notification-overlay.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 750,
  },
};

@Component({
  selector: 'notification-button',
  templateUrl: 'notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig }],
  standalone: true,
  imports: [
    MatButtonModule,
    CdkOverlayOrigin,
    MatIconModule,
    CdkConnectedOverlay,
    NotificationOverlayComponent,
    AsyncPipe,
    IconDotDirective,
  ],
})
export class NotificationButtonComponent implements OnInit {
  constructor(
    private store: Store<NotificationsState>,
    private toastService: ToastService,
  ) {}
  private destroyRef = inject(DestroyRef);

  @ViewChild(MatButton) notificationsBtn: MatButton | undefined;

  private isOpen = new BehaviorSubject(false);
  isOpen$ = this.isOpen.asObservable();

  hasUnreadNotifications$ = this.store.select(hasUnreadNotifications);
  hasNewNotifications$ = this.store.select(hasNewNotifications);

  positions = [
    new ConnectionPositionPair({ originX: 'center', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
  ];

  toggleNotificationOverlay = () => {
    this.store.dispatch(dismissAllNotifications());
    this.isOpen.next(!this.isOpen.value);
  };

  ngOnInit(): void {
    combineLatest([this.hasNewNotifications$, this.isOpen$])
      .pipe(
        tap(([hasNewNotifications, isOverlayOpen]) => {
          if (hasNewNotifications && !isOverlayOpen) {
            this.toastService.show();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
