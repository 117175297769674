/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  changeThemeAccordingToOS,
  currentCluster,
  currentClusterId,
  isAuthPage,
  isLoading,
  isNebulaRegularUserAccess,
  setErrorSnackbarDuration,
  setSnackbarDuration,
  setTheme,
  theme,
} from '@app/core/ngrx';
import { userIsAdmin } from '@app/core/ngrx/user.selectors';
import { SideNavMenu } from '@app/core/side-nav/side-nav.component';
import { AppState, Themes } from '@app/core/types';
import { Store } from '@ngrx/store';
import { DurationInMs } from '@shared/types/snapshots';
import { Observable, combineLatest, filter, map, merge, mergeMap, of, pluck, tap } from 'rxjs';
import { isCluster2xSelector } from './core/ngrx/cluster.selectors';
import { hasLicenseSelector } from './core/ngrx/license.selectors';

declare global {
  interface Window {
    GMC: {
      setTheme(theme: Themes): void;
      setErrorSnackbarDuration(durationInMs: DurationInMs): void;
      setSnackbarDuration(durationInMs: DurationInMs): void;
      triggerGG9Request: () => void;
    };
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private mediaMatcher: MediaMatcher,
    private ref: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.store
      .select(theme)
      .pipe(
        tap((currentTheme) => {
          this.document.querySelector('html')?.classList.toggle('dark-theme', currentTheme === Themes.DARK);
          this.document.querySelector('html')?.classList.toggle('light-theme', currentTheme !== Themes.DARK);
        }),
      )
      .subscribe();
    const mediaQueryList = this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)');
    this.store.dispatch(changeThemeAccordingToOS({ theme: mediaQueryList.matches ? Themes.DARK : Themes.LIGHT }));

    const http = inject(HttpClient);
    window.GMC = {
      setTheme: (theme: Themes) => {
        this.store.dispatch(setTheme({ theme }));
      },
      setSnackbarDuration: (durationInMs) => {
        this.store.dispatch(setSnackbarDuration({ duration: durationInMs }));
      },
      setErrorSnackbarDuration: (durationInMs) => {
        this.store.dispatch(setErrorSnackbarDuration({ duration: durationInMs }));
      },
      triggerGG9Request: () => {
        const clusterId = this.store.selectSignal(currentClusterId)()!;
        http.get(`/api/v1/ignite3/${clusterId}/query/sql/execution-request`).subscribe({
          error: (e) => {
            console.error(e);
          },
        });
      },
    };
  }

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('header') header!: ElementRef;

  screenWidth = window.innerWidth;

  hideSidebar$ = merge(
    of(true),
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route) => route.data),
      pluck('hideSidebar'),
    ),
  );

  isOnInitPage$ = this.store.select(isAuthPage);

  navigationItems$: Observable<SideNavMenu> = combineLatest([
    this.store.select(currentCluster),
    this.store.select(isNebulaRegularUserAccess),
    this.store.select(userIsAdmin),
    this.store.select(hasLicenseSelector),
    this.store.select(isCluster2xSelector),
  ]).pipe(
    map(([clusterId, isNebulaRegularUserAccess, isAdmin, hasOnPremiseLicense, is2x]) => {
      const userItems = [
        ...(!!clusterId && !!hasOnPremiseLicense
          ? [
              {
                routerLink: ['clusters', clusterId, 'monitoring-dashboard'],
                shortLabel: `Dashboard`,
                label: `Dashboard`,
                icon: 'ggcc:dashboard-alt',
              },
              {
                routerLink: ['clusters', clusterId, 'alerting'],
                shortLabel: `Alerting`,
                label: `Alerting`,
                icon: 'ggcc:alerting-alt',
              },
              {
                routerLink: ['clusters', clusterId, 'tracing'],
                shortLabel: `Tracing`,
                label: `Tracing`,
                icon: 'ggcc:tracing-alt',
              },
              {
                routerLink: ['clusters', clusterId, 'sql'],
                shortLabel: 'Queries',
                label: 'Queries',
                icon: 'ggcc:queries-alt',
              },
              {
                routerLink: ['clusters', clusterId, 'compute'],
                shortLabel: `Compute`,
                label: `Compute`,
                icon: 'material-filled:task-alt',
              },
              {
                routerLink: ['clusters', clusterId, 'code-deployment'],
                shortLabel: 'Deployment',
                label: 'Deployment',
                icon: 'ggcc:deployment-alt',
              },
              ...(is2x
                ? [
                    {
                      routerLink: ['clusters', clusterId, 'caches'],
                      shortLabel: `Caches`,
                      label: `Caches`,
                      icon: 'ggcc:caches-alt',
                    },
                  ]
                : [
                    {
                      routerLink: ['clusters', clusterId, 'tables'],
                      shortLabel: `Tables`,
                      label: `Tables`,
                      icon: 'ggcc:caches-alt',
                    },
                  ]),
              ...(isNebulaRegularUserAccess
                ? []
                : [
                    {
                      routerLink: ['clusters', clusterId, 'snapshots'],
                      shortLabel: `Snapshots`,
                      label: `Snapshots`,
                      icon: 'ggcc:snapshot-alt',
                    },
                  ]),
            ]
          : []),
      ];

      const adminItems = !!isAdmin
        ? [
            ...(userItems.length ? [{ separator: true } as const] : []),
            {
              routerLink: ['admin'],
              shortLabel: `Admin`,
              label: `Administration`,
              icon: 'ggcc:admin-alt',
            },
          ]
        : [];

      return [...userItems, ...adminItems];
    }),
  );

  isLoading$ = this.store.select(isLoading);

  ro!: ResizeObserver;
  headerHeight = 0;

  ngAfterViewInit() {
    this.ro = new ResizeObserver((entry: ResizeObserverEntry[]) => {
      if (!entry[0]) return;
      this.headerHeight = entry[0].contentRect.height;
      this.ref.detectChanges(); // Looks like this is not needed, but it should be here if I understand right how angular works.
    });

    this.ro.observe(this.header.nativeElement);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent & { target: Window }) {
    this.screenWidth = event.target.innerWidth;
  }

  ngOnDestroy() {
    this.ro.disconnect();
  }
}
