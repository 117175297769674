/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { clusterTag } from '@app/core/ngrx';
import { toggleNotificationStatus } from '@app/core/notification/ngrx';
import { AppState } from '@app/core/types';
import { getUrlRegExp } from '@common/constants/url-regexp';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/types/notification';
import { DetectEllipsisDirective } from '../../../common/directives/detect-ellipsis.directive';
import { DateAgoPipe } from '../../../common/pipes/date-ago.pipe';

@Component({
  selector: 'notification-message',
  templateUrl: 'notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DetectEllipsisDirective, MatTooltipModule, MatButtonModule, NgClass, DateAgoPipe],
})
export class NotificationMessageComponent {
  private router = inject(Router);
  private store: Store<AppState> = inject(Store);

  notification = input<Notification>();
  showActions = input<boolean | undefined>(false);
  closeNotification = output<void>();

  clusterTag = computed((): string | null => {
    const notification = this.notification();
    if (!notification || !notification.clusterId) return null;
    return this.store.selectSignal(clusterTag(notification.clusterId))();
  });

  toggleNotification = (event: MouseEvent) => {
    const notification = this.notification();
    event.stopPropagation();
    if (!notification) return;
    const { id, read } = notification;
    this.store.dispatch(toggleNotificationStatus({ id, read: !read }));
  };

  navigate = () => {
    const notification = this.notification();
    if (!notification) return;
    if (getUrlRegExp(['http', 'https']).test(notification.targetUrl)) {
      window.open(notification.targetUrl, '_blank');
    } else {
      this.router.navigateByUrl(notification.targetUrl);
    }
    this.closeNotification.emit();
    this.store.dispatch(toggleNotificationStatus({ id: notification.id, read: true }));
  };

  dismiss = (event: MouseEvent) => {
    event.stopPropagation();
    this.closeNotification.emit();
  };
}
