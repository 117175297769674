<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Add cluster" gta="header-add-cluster-menu-trigger"
  ><mat-icon>add</mat-icon></button
>
<mat-menu #menu="matMenu">
  <create-nebula-cluster-button *showForTarget="'hosted'">
    <ng-template createNebulaClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
      <a
        mat-menu-item
        [routerLink]="routerLink$ | async"
        [disabled]="disabled$ | async"
        gta="header-menu-create-cluster-button"
      >
        <mat-icon svgIcon="ggcc:gridgain" class="add-cluster-menu-icon"></mat-icon>
        Create Managed cluster
      </a>
    </ng-template>
  </create-nebula-cluster-button>
  <attach-cluster-button [product]="AttachDialogMode.GRIDGAIN">
    <ng-template attachClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
      <a
        mat-menu-item
        [routerLink]="routerLink$ | async"
        [disabled]="disabled$ | async"
        gta="attach-cluster-button"
        gta="header-menu-attach-cluster-button-gg"
      >
        <mat-icon svgIcon="ggcc:gridgain" class="add-cluster-menu-icon"></mat-icon>
        Attach GridGain
      </a>
    </ng-template>
  </attach-cluster-button>
  <attach-cluster-button [product]="AttachDialogMode.APACHE_IGNITE">
    <ng-template attachClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
      <a
        mat-menu-item
        [routerLink]="routerLink$ | async"
        [disabled]="disabled$ | async"
        gta="attach-cluster-button"
        gta="header-menu-attach-cluster-button-ai"
      >
        <mat-icon svgIcon="ggcc:ignite" class="add-cluster-menu-icon"></mat-icon>
        Attach Apache Ignite
      </a>
    </ng-template>
  </attach-cluster-button>
</mat-menu>
