/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { authConfig, getAuthConfigErr } from '@app/core/ngrx';
import { AuthService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { lastValueFrom, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { extractError } from './common/utils';

export const authConfigAppInitializer: EnvironmentProviders = provideAppInitializer(() => {
  const auth = inject(AuthService);
  const store = inject(Store);
  return lastValueFrom(
    auth.getAuthConfig().pipe(
      tap((config) => store.dispatch(authConfig({ config }))),
      catchError((err) => {
        store.dispatch(
          getAuthConfigErr({
            error: extractError(err, `Failed to get auth config`),
          }),
        );
        return of(undefined);
      }),
      take(1),
    ),
  );
});
